import request from '@/plugins/axios'

// //产品档案列表
// export const apiProductFileLists = (params: any) =>
//   request.get('/card.product_file/lists', { params })
//
// //获取跳转链接
// export const apiGenerateJumpLink = (data: any) =>
//   request.post('/card.product_file/generateJumpLink', data)
//添加电子券订单
export const addElectronicVoucherOrder = (data: any) =>
  request.post('/card.electronic_voucher/addElectronicVoucherOrder', data)
//添加电子券订单
// 编辑电子券订单
export const editElectronicVoucherOrder = (data: any) =>
  request.post('/card.electronic_voucher/editElectronicVoucherOrder', data)
//添加电子券订单
export const electronicVoucherOrderList = (params: any) =>
  request.get('/card.electronic_voucher/lists', { params })

//获取电子券订单详情
export const getElectronicVoucherOrderInfo = (params: any) =>
  request.get('/card.electronic_voucher/getElectronicVoucherOrderInfo', { params })

//审核电子券订单
export const reviewEVoucherOrders = (data: any) =>
  request.post('/card.electronic_voucher/reviewEVoucherOrders', data)
