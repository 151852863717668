import request from '@/plugins/axios'
import * as Interface from '@/api/card/physical_vouchers.d'

//备券信息列表
export const apiPhysicalVouchersCouponBackLists = (params: Interface.CouponBackLists_Req): Promise<Interface.CouponBackLists_Req> =>
  request.get('/card.PhysicalVouchers/couponBackList', {params})

//添加备券信息
export const apiPhysicalVouchersAdd = (params: any) => request.post('/card.PhysicalVouchers/add', params)

//获取实体券材质价格配置
export const apiPhysicalVouchersMaterialList = (params: any) => request.post('/card.PhysicalVouchers/materialList', params)

export const apiPhysicalVouchersApprovalCouponBack = (params: any) => request.post('/card.PhysicalVouchers/approvalCouponBack', params)

export const apiPhysicalVouchersCouponBackDetail = (params: any) => request.post('/card.PhysicalVouchers/couponBackDetail', params)

export const apiOrderCheckCardNoData = (params: any) => request.post('/card.PhysicalVouchersOrder/checkCardNoData', params)

export const apiOrderAdd = (params: any) => request.post('/card.PhysicalVouchersOrder/add', params)

export const apiOrderLists = (params: any): Promise<any> =>
  request.get('/card.PhysicalVouchersOrder/lists', {params})

export const apiOrderDetail = (params: any) => request.post('/card.PhysicalVouchersOrder/orderDetail', params)
export const apiGetCategroy = (params: any) => request.post('/card.PhysicalVouchersOrder/getCategroy', params)

export const apiOrderApproval = (params: any) => request.post('/card.PhysicalVouchersOrder/approval', params)

