import request from '@/plugins/axios'

//产品档案列表
export const apiProductFileLists = (params: any) =>
  request.get('/card.product_file/lists', { params })

//电子券实体券订单列表
export const apiCardSaleOrderLists = (params: any) =>
  request.get('/card.CardSaleOrder/lists', { params })

//电影院城市列表
export const apiProductFileListOfCinemaCities = (params: any) =>
  request.get('/movie.BasicData/city', { params })

//电影院列表
export const apiProductFileGetCinemaList = (params: any) =>
  request.get('/card.product_file/getCinemaList', { params })

//添加产品档案啊
export const apiAddProductProfile = (data: any) => request.post('/card.product_file/add', data)

//添加产品档案啊
export const apiEditProductProfile = (data: any) => request.post('/card.product_file/editProfileInfo', data)

//获取产品档案日志
//获取产品档案无效商品
export const apiGetAuditLog = (params: any) =>
  request.get('/card.product_file/getAuditLog', { params })
//选择商品到产品档案

export const apiAddProductProfileProduct = (data: any) => request.post('/card.product_file/checkProductProfilesProducts', data)

//获取产品档案商品
export const apiGetProductProfileProduct = (data: any) =>
  request.post('/card.product_file/getFileProducts', data)
//删除产品档案商品
export const apiDelProductProfileProduct = (data: any) =>
  request.post('/card.product_file/delFileProducts', data)

//编辑产品分组
export const apiEditFileProductsGroupName = (data: any) =>
  request.post('/card.product_file/editFileProductsGroupName', data)

//获取产品档案无效商品
export const apiInvalidDataProduct = (params: any) =>
  request.get('/card.product_file/getLapsedCommodity', { params })

//获取跳转链接
export const apiGetPriceRules = (data: any) =>
  request.post('/card.product_file/getPriceRules', data)

//获取跳转链接
export const apiGenerateJumpLink = (data: any) =>
  request.post('/card.product_file/generateJumpLink', data)

//审批
export const apiApproval = (data: any) =>
  request.post('/card.ProductProfilesAuditingLog/approval', data)

//获取跳转链接
export const apiGetProfileInfo = (params: any) =>
  request.get('/card.product_file/getProfileInfo', { params })

// 获取产品档案编号
export const apiGetProductProfileNumber = (params: any) =>
  request.get('/card.product_file/getFileNumber', { params })
