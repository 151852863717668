



































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { debounce } from "@/utils/util";
import { apiGetCustomerList, apiShopLists } from "@/api/shop";
import { apiProductFileLists } from "@/api/productFile";
import {
    addElectronicVoucherOrder,
    getElectronicVoucherOrderInfo,
} from "@/api/electronicVoucher";
import config from "@/config";
import { apiGetCategroy } from "@/api/card/physical_vouchers";
import { apiOtherResources } from "@/api/goods";
@Component({})
export default class voucherCardAdd extends Vue {
    steps: any = 1;
    cardMallType: any = [
        {
            title: "按次兑换",
            list: [
                {
                    id: 1,
                    title: "电影次票券",
                    discrble:
                        "按次兑换电影票，可限制兑换城市，兑换影院，以及兑换方式（线上线下）",
                },
                {
                    id: 3,
                    title: "N选1兑换券",
                    discrble:
                        "可限定用户在指定几种商品里面选择一个商品进行兑换",
                },
            ],
        },
        {
            title: "储值兑换",
            list: [
                {
                    id: 5,
                    title: "全场通用储值券",
                    discrble: "可购买商城所有商品",
                },
                {
                    id: 2,
                    title: "指定品类储值券",
                    discrble: "可限定用户在限定的品类下进行商品的购买",
                },
                {
                    id: 4,
                    title: "指定商品储值券",
                    discrble: "可限定用户只能购买指定的几种商品",
                },
            ],
        },
    ];
    form_extens: any = {
        sales_discount: "", //销售折扣
        order_amount: "", //订单金额
        basePrice: "", //订单金额
    };
    id: any = "";
    auditing_log: any = [];
    // 添加商城表单数据
    form: any = {
        type: "",
        shop_id: "", //商城id
        customer_id: "", //客户
        product_profiles_id: "", //产品档案
        price_range: "", //档位价
        discount_ceiling: "", //抵扣上限
        sales_price: "", //销售单价
        sales_sum: "", //销售数量
        number_votes: "", //内含票数
        sales_discount: "", //销售折扣
        order_amount: "", //订单金额
        remarks: "", //订单金额
        distribution_form: "1", //发放形式
        receive_key_phone: "", //接收秘钥手机号
        email_title: "", //邮件标题
        receive_email: "", //邮件地址
        email_content: "", //邮件内容
        effectiveTime: "", //激活时间时间
        expirationTime: "", //有效时间
        inclusive_amount: "", //内含金额
        phoneList: [], //手机号列表
        auto_bind: 0, //是否自动绑券
        sms_content: "", //短信内容
        link_type: "",
        link_id: "",
        // jhfs: 1,
        // sd: 1,
        // jzj: '',
        // dk: '',
        // dj: '',
        // sl: '',
        // qs: 1,
        // ps: '',
        // je: '',
        // zk: '',
        // bz: '',
    };
    action = `${config.baseURL}/platformapi/upload/xlsx_file`;

    // 表单校验
    rules = {
        inclusive_amount: [
            {
                required: true,
                message: "请输入内含金额",
                trigger: "blur",
                validator: (rule: any, value: any, callback: any) => {
                    if (this.form.type == 1 || this.form.type == 3) {
                        callback(); // 当type为1或3时，不验证必填性，直接通过验证
                    } else {
                        if (!value) {
                            callback(new Error("请输入内含金额"));
                        } else {
                            callback();
                        }
                    }
                },
            },
        ],
        customer_id: [
            {
                required: true,
                message: "请选择客户",
                trigger: "blur",
            },
        ],

        shop_id: [
            {
                required: true,
                message: "请选择商城",
                trigger: "blur",
            },
        ],
        product_profiles_id: [
            {
                required: true,
                message: "请选择档案",
                trigger: "blur",
            },
        ],
        expirationTime: [
            {
                required: true,
                message: "请选择到期时间",
                trigger: "blur",
            },
        ],
        link_type: [
            { required: true, message: "请选择跳转链接", trigger: "blur" },
        ],
        link_id: [{ validator: this.checkLink, trigger: "blur" }],
        effectiveTime: [
            {
                required: true,
                message: "请选择激活时间",
                trigger: "blur",
            },
        ],
        company_address: [
            {
                required: true,
                message: "请输入客户地址",
                trigger: "blur",
            },
        ],
        company_bl_img: [
            {
                required: true,
                message: "请上传证件",
                trigger: "blur",
            },
        ],
        company_bl_no: [
            {
                required: true,
                message: "请输入证件号码",
                trigger: "blur",
            },
        ],
        sales_price: [
            {
                required: true,
                message: "请填写销售单价",
                trigger: "blur",
            },
        ],
        number_votes: [
            {
                required: true,
                message: "请填写内含票数",
                trigger: "blur",
                validator: (rule: any, value: any, callback: any) => {
                    if (this.form.type != 1 && this.form.type != 3) {
                        callback(); // 当type为1或3时，不验证必填性，直接通过验证
                    } else {
                        if (!value) {
                            callback(new Error("请填写内含票数"));
                        } else {
                            callback();
                        }
                    }
                },
            },
        ],
        sales_sum: [
            {
                required: true,
                message: "请填写销售数量",
                trigger: "blur",
            },
        ],
        distribution_form: [
            {
                required: true,
                message: "请选择发放形式",
                trigger: "blur",
            },
        ],
        contact_mobile: [
            {
                required: true,
                message: "请输入联系人手机号",
                trigger: "blur",
            },
            {
                validator: (rule: any, value: any, callback: any) => {
                    const regExp = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
                    if (!regExp.test(value)) {
                        callback(new Error("请输入正确的手机号"));
                    } else {
                        callback();
                    }
                },
                trigger: "change",
            },
        ],
        receive_key_phone: [
            {
                required: true,
                message: "请输入秘钥接收手机号",
                trigger: "blur",
            },
            {
                validator: (rule: any, value: any, callback: any) => {
                    const regExp = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
                    if (!regExp.test(value)) {
                        callback(new Error("请输入正确的手机号"));
                    } else {
                        callback();
                    }
                },
                trigger: "change",
            },
        ],
        contact_name: [
            {
                required: true,
                message: "请输入联系人名称",
                trigger: "blur",
            },
        ],
        receive_email: [
            {
                required: true,
                message: "请输入邮件地址",
                trigger: "blur",
            },
        ],
        email_title: [
            {
                required: true,
                message: "请输入邮件主题",
                trigger: "blur",
            },
        ],
        email_content: [
            {
                required: true,
                message: "请输入邮件内容",
                trigger: "blur",
            },
        ],
        account: [
            {
                required: true,
                message: "请输入集采账户",
                trigger: "blur",
            },
        ],
    };
    rules1 = {
        distribution_form: [
            {
                required: true,
                message: "请选择发放形式",
                trigger: "blur",
            },
        ],
        sms_content: [
            {
                required: true,
                message: "请填写短信内容",
                trigger: "blur",
            },
            { validator: this.codeValidator, trigger: "blur" },
        ],
        auto_bind: [
            {
                required: true,
                message: "请选择是否自动绑券",
                trigger: "blur",
            },
        ],
        receive_key_phone: [
            {
                required: true,
                message: "请输入秘钥接收手机号",
                trigger: "blur",
            },
            {
                validator: (rule: any, value: any, callback: any) => {
                    const regExp = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
                    if (!regExp.test(value)) {
                        callback(new Error("请输入正确的手机号"));
                    } else {
                        callback();
                    }
                },
                trigger: "change",
            },
        ],
        receive_email: [
            {
                required: true,
                message: "请输入邮件地址",
                trigger: "blur",
            },
        ],
        email_title: [
            {
                required: true,
                message: "请输入邮件主题",
                trigger: "blur",
            },
        ],
        email_content: [
            {
                required: true,
                message: "请输入邮件内容",
                trigger: "blur",
            },
        ],
    };
    shopList: any[] = [];
    custom_list: any[] = [];
    productFileLists: any[] = [];
    codeValidator(rule: any, value: any, callback: any) {
        if (value == "") {
            return callback(new Error("请输入短信内容"));
        }
        if (!value.includes("$code$") && this.form.auto_bind == 0) {
            return callback(new Error("请填写福利券码$code$生成位置"));
        }
        callback();
    }
    categoryList: any = [];
    async shopChange(e: any) {
        this.categoryList = await apiGetCategroy({ sid: e });
    }
    linkSelect() {
        this.form.link_id = "";
    }
    checkLink(rule: any, value: any, callback: any) {
        if (this.form.link_type != 1 && this.form.link_type != 3) {
            if (value == "") return callback(new Error("请选择跳转类目"));
        }
        callback();
    }
    prev() {
        this.steps--;
    }

    next(formName: string) {
        if (this.steps == 1) {
            this.getProductFileLists();
        }

        // 验证表单
        const refs = this.$refs[formName] as HTMLFormElement;
        refs.validate((valid: boolean): any => {
            if (!valid) return;
            if (this.steps == 2) {
                if (this.form_extens.sales_discount < 0.1) {
                    this.$message.error("销售折扣不得低于0.1");
                    return false;
                }
                if (
                    this.form.type == 2 ||
                    this.form.type == 4 ||
                    this.form.type == 5
                ) {
                    if (this.form_extens.sales_discount > 1) {
                        this.$message.error("销售折扣不得大于1");
                        return false;
                    }
                }
                if (this.form_extens.sales_discount < 0.5) {
                    this.$confirm(
                        "销售折扣率很低，此订单可能会亏损，是否继续？",
                        "提示",
                        {
                            confirmButtonText: "继续",
                            cancelButtonText: "返回继续修改",
                            type: "warning",
                        }
                    )
                        .then(() => {
                            this.steps++;
                        })
                        .catch(() => {
                            return false;
                        });
                } else {
                    this.steps++;
                }
            } else {
                this.steps++;
            }
        });
    }
    handleError(res: any) {
        console.log(res);
    }

    handleSuccess(res: any) {
        if (res.code == 1) {
            this.form.phoneList = res.data;
        } else {
            this.$message.error("文件格式错误或者大小超过10兆");
        }
    }
    addPhone() {
        this.form.phoneList.push({
            phone: "",
            quantity: "",
        });
    }

    delphone(index: number) {
        this.form.phoneList = this.form.phoneList.filter(
            (element: any, indexs: number) => indexs !== index
        );
    }
    // 点击表单提交
    onSubmit(formName: string) {
        if (this.form.distribution_form == 2) {
            if (this.form.phoneList.length < 1) {
                this.$message.error("请填写手机号信息");
                return false;
            }
            const sales_sum = this.form.sales_sum;
            console.log(sales_sum);
            let phone_quantity = 0;

            let phones: any = [];
            // 使用循环计算number值的总和
            for (let i = 0; i < this.form.phoneList.length; i++) {
                if (!this.form.phoneList[i].quantity) {
                    this.$message.error("请输入数量");
                    return false;
                }
                if (this.form.phoneList[i].quantity > 10) {
                    this.$message.error("单个手机号最多只能发送10个电子码");
                    return false;
                }
                if (phones.includes(this.form.phoneList[i].phone)) {
                    this.$message.error(
                        "手机号码存在重复，如需单个手机号发送多个电子码，请更改数量即可"
                    );
                    return false;
                }
                phones.push(this.form.phoneList[i].phone);

                phone_quantity =
                    Number(phone_quantity) +
                    Number(this.form.phoneList[i].quantity);
            }
            console.log(phone_quantity, sales_sum);
            if (phone_quantity != sales_sum) {
                this.$message.error(
                    "手机号发送的总数量不等于订单销售数量，请重新输入"
                );
                return false;
            }
        }
        const form_ref = this.$refs[formName] as HTMLFormElement;
        form_ref.validate(async (valid: boolean) => {
            if (!valid) return;
            const res = await addElectronicVoucherOrder(this.form);
            this.$router.go(-1);
        });
    }

    async getCustomerList() {
        const custom_list_array = await apiGetCustomerList({
            page_type: 0,
            type: 0,
        });
        this.custom_list = custom_list_array.lists;
    }

    selectProductProfile(res: any) {
        const foundItem = this.productFileLists.find((item) => item.id === res);
        if (foundItem) {
            this.form.price_range = foundItem.price_range;
            this.form.discount_ceiling = foundItem.discount_ceiling;
            this.form_extens.basePrice = foundItem.discount_ceiling;
        }
    }
    calculateTotalOrderPrice() {
        if (this.form.sales_price != "" && this.form.sales_sum != "") {
            this.form_extens.order_amount = this.amountCalculation(
                this.form.sales_price,
                this.form.sales_sum
            );
        }
        if (
            this.form.sales_price != "" &&
            this.form.number_votes != "" &&
            this.form.price_range > 0
        ) {
            const price1 = this.amountCalculation_division(
                this.form.sales_price,
                this.form.number_votes
            );
            this.form_extens.sales_discount = this.amountCalculation_division(
                price1,
                this.form.price_range
            );
        }
        if (
            (this.form.type == 2 ||
                this.form.type == 4 ||
                this.form.type == 5) &&
            this.form.sales_price != "" &&
            this.form.inclusive_amount != ""
        ) {
            this.form_extens.sales_discount = this.amountCalculation_division(
                this.form.sales_price,
                this.form.inclusive_amount
            );
        }
        if (
            this.form.type == 1 &&
            this.form.sales_price != "" &&
            this.form.number_votes != "" &&
            this.form.discount_ceiling != ""
        ) {
            const price1 = this.amountCalculation_division(
                this.form.sales_price,
                this.form.number_votes
            );
            this.form_extens.sales_discount = this.amountCalculation_division(
                price1,
                this.form.discount_ceiling,
                2
            );
        }
    }
    amountCalculation(number1: string, number2: string) {
        // 将输入的金额和乘数转换为数字
        const amount = parseFloat(number1);
        const multiplier = parseFloat(number2);
        // 计算金额乘以乘数，并保留两位小数
        const result = amount * multiplier;
        return result.toFixed(2);
    }
    amountCalculation_division(
        number1: string,
        number2: string,
        fraction: number = 4
    ) {
        // 将输入的金额和乘数转换为数字
        const amount = parseFloat(number1);
        const multiplier = parseFloat(number2);
        // 计算金额乘以乘数，并保留两位小数
        const result = amount / multiplier;
        return result.toFixed(fraction);
    }
    async getProductFileLists() {
        const product_file_list_array = await apiProductFileLists({
            page_type: 0,
            product_type: this.form.type,
            open_status: 1,
        });
        this.productFileLists = product_file_list_array.lists;
    }
    async getElectronicVoucherOrder() {
        const electronicVoucherOrderInfo = await getElectronicVoucherOrderInfo({
            id: this.id,
        });
        console.log(electronicVoucherOrderInfo);
        this.auditing_log = electronicVoucherOrderInfo.auditing_log;
        // 循环obj1的键
        for (let key in this.form) {
            // 检查key是否存在于obj2中
            if (key in electronicVoucherOrderInfo) {
                // 如果存在，则将obj2的值赋给obj1对应的键
                this.form[key] = electronicVoucherOrderInfo[key];
            }
        }
        this.calculateTotalOrderPrice();
        // this.form = electronicVoucherOrderInfo
        /*  console.log(this.form,'this.form')*/

        this.shopChange(this.form.shop_id);
        // this.orderInfo = electronicVoucherOrderInfo
    }
    changeShop() {
        this.form.shop_id = "";
        // this.getShop()
    }
    async getShop() {
        const shopListArray = await apiShopLists({
            expires_status: 1,
            pageType: 1,
        });
        this.shopList = shopListArray.lists;
    }
    specialResource: any = {};
    async getSpecialResource() {
        let res = await apiOtherResources({
            page_no: 1,
            page_size: 1000,
            service_fee_status: 0,
        });
        this.specialResource = res.lists;
    }
    /** S ods **/
    created() {
        this.id = this.$route.query.id;
        this.getCustomerList();
        this.getProductFileLists();
        this.getSpecialResource();
        this.getElectronicVoucherOrder();
        this.getShop();
    }
}
